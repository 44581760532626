import React from 'react';

const Loader = () => {
  return (
    <div
      className={['spinner-border', 'mr-1', 'text-primary'].join(' ')}
      role="status"
      style={{ position: 'fixed', top: '50%', left: '50%', zIndex: '1500' }}
    ></div>
  );
};

export default Loader;
