import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// import NavLeft from './NavLeft';
import NavRight from './NavRight';
import { ConfigContext } from '../../../contexts/ConfigContext';
import * as actionType from '../../../store/actions';
import logo from '../../../assets/images/QPAY-white.png';

const NavBar = () => {
  const configContext = useContext(ConfigContext);
  const {
    collapseMenu,
    headerBackColor,
    headerFixedLayout,
    layout,
    subLayout,
    mobileToogler,
  } = configContext.state;
  const [moreToggle, setMoreToggle] = useState(mobileToogler);
  const isRecipient = useSelector(
    (state) => state.auth.user.role.id === 'recipient'
  );

  const { dispatch } = configContext;

  let headerClass = [
    'navbar',
    'pcoded-header',
    'navbar-expand-lg',
    headerBackColor,
  ];
  if (headerFixedLayout && layout === 'vertical') {
    headerClass = [...headerClass, 'headerpos-fixed'];
  }

  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  const navToggleHandler = () => {
    dispatch({ type: actionType.COLLAPSE_MENU });
  };

  let moreClass = ['mob-toggler'];
  let collapseClass = ['collapse navbar-collapse'];
  if (moreToggle) {
    moreClass = [...moreClass, 'on'];
    collapseClass = [...collapseClass, 'd-block'];
  }

  let navBar = (
    <React.Fragment>
      <div className='m-header'>
        <Link
          to='#'
          className={toggleClass.join(' ')}
          id='mobile-collapse'
          onClick={navToggleHandler}
        >
          <span />
        </Link>
        <Link
          to={isRecipient ? '/history' : '/main_reports'}
          style={{ cursor: 'pointer' }}
          className='b-brand'
        >
          <img src={logo} alt='logo' height='50' />
        </Link>
        <Link
          to='#'
          className={moreClass.join(' ')}
          onClick={() => {
            dispatch({ type: actionType.MOBILE_TOOGLE });

            setMoreToggle(!moreToggle);
          }}
        >
          <i className='feather icon-more-vertical' />
        </Link>
      </div>
      <div className={collapseClass.join(' ')}>
        {/* <NavLeft/> */}
        <NavRight />
      </div>
    </React.Fragment>
  );

  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    navBar = <div className='container'>{navBar}</div>;
  }

  return (
    <React.Fragment>
      <header className={headerClass.join(' ')}>{navBar}</header>
      {/* <p>
        Пользователь заблокирован, Вы не можете принимать платежи, действия в
        личном кабинете ограничены
      </p> */}
    </React.Fragment>
  );
};

export default NavBar;
