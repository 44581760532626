import hupu from '../../apis/hupu';

export const FETCH_PAYS = 'FETCH_PAYS';
export const FETCH_REPORT = 'FETCH_REPORT';
export const POST_SUPPORT = 'POST_SUPPORT';
export const FETCH_REQUISITES = 'FETCH_REQUISITES';
// export const PAYOUT = 'PAYOUT'

export const fetchPays = (
  limit,
  offset,
  order_by,
  dir,
  date_from,
  date_to,
  id
) => {
  return async (dispatch) => {
    try {
      const response = await hupu.get('/pays', {
        params: id
          ? {
              limit,
              offset,
              order_by,
              dir,
              date_from,
              date_to,
              recipient_id: id,
            }
          : {
              limit,
              offset,
              order_by,
              dir,
              date_from,
              date_to,
            },
      });

      if (response.data.success) {
        dispatch({ type: FETCH_PAYS, payload: response.data });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const fetchReport = (fun) => {
  return async (dispatch) => {
    try {
      const response = await hupu.get('/pays/stat', {
        params: {
          function: fun,
        },
      });

      if (response.data.success) {
        dispatch({ type: FETCH_REPORT, payload: response.data });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const postSupport = (title, comment) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();

      formData.append('title', title);
      formData.append('comment', comment);

      const response = await hupu.post('/support', formData);

      if (response.data.success) {
        dispatch({ type: POST_SUPPORT, payload: response.data });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const fetchRequisites = () => {
  return async (dispatch) => {
    try {
      const response = await hupu.get('/b2p_info');

      if (response.data.success) {
        dispatch({ type: FETCH_REQUISITES, payload: response.data });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};
