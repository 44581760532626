import { Link } from 'react-router-dom';

class Client {
    constructor(
        id,
        name,
        position,
        img,
        email,
        phone,
        title,
        city,
        money,
        income,
        address,
        ctime,
        last_access,
        is_confirm,
        is_disabled
    ) {
        this.id = id;
        this.name = name;
        this.position = position;
        this.img = img;
        this.email = email;
        this.phone = phone;
        this.title = title;
        this.city = city;
        this.money = money;
        this.income = income;
        this.address = address;
        this.ctime = ctime;
        this.last_access = last_access;
        this.is_confirm = is_confirm;
        this.is_disabled = is_disabled;
    }
}

export default Client;

export const newClient = client => {
    return new Client(
        client.id,
        (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',

                    alignItems: 'center',
                }}
            >
                <span
                    style={{
                        fontSize: '10px',
                        color: '#FF00FF',

                        transform: 'translateY(6px)',
                    }}
                    className='badge'
                >
                    {client.role.id === 'admin' ? 'Cуперюзер' : ''}
                </span>
                <div>{client.name}</div>{' '}
                <span
                    className={`badge badge-light-${
                        client.is_disabled ? 'danger' : client.is_confirm ? 'success' : 'danger'
                    }`}
                >
                    {client.is_disabled
                        ? 'Заблокирован'
                        : client.is_confirm
                        ? 'Активирован'
                        : 'Не активирован'}
                </span>
            </div>
        ),
        client.position,
        (
            <div>
                <img src={client.avatar} height={35} style={{ borderRadius: '5px' }} alt='' />
                <div className='overlay-edit'>
                    <Link
                        to={`/user/${client.id}`}
                        type='button'
                        className='btn btn-icon btn-success'
                    >
                        <i className='feather icon-edit'>
                            <span className='sr-only' />
                        </i>
                    </Link>
                </div>
            </div>
        ),
        client.email,
        client.phone,
        client.title,
        client.city,
        client.money,
        client.income,
        client.address,
        client.ctime,
        client.last_access,
        (
            <div>
                <span className={`badge badge-light-${!client.is_confirm ? 'danger' : 'success'}`}>
                    {!client.is_confirm ? 'Нужна Активация' : 'Активирован'}
                </span>
            </div>
        ),
        (
            <div>
                <span
                    className={`badge badge-light-${
                        client.is_disabled ? 'danger' : client.is_confirm ? 'success' : 'danger'
                    }`}
                >
                    {client.is_disabled
                        ? 'Заблокирован'
                        : client.is_confirm
                        ? 'Активирован'
                        : 'Не активирован'}
                </span>
                <div className='overlay-edit'>
                    <Link
                        to={`/user/${client.id}`}
                        type='button'
                        className='btn btn-icon btn-success'
                    >
                        <i className='feather icon-check-circle'>
                            <span className='sr-only' />
                        </i>
                    </Link>
                </div>
            </div>
        )
    );
};
