import axios from 'axios';

export default axios.create({
  baseURL:
    window.location.host === 'my.qpay.club'
      ? 'https://api.qpay.club'
      : window.location.host === 'my.hupu.ru'
      ? 'https://api.hupu.ru'
      : 'https://api.dev.qpay.club',

  withCredentials: true,
});
