import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import AdminLayout from './layouts/AdminLayout';
import AdminGuard from './components/Auth/AdminGuard';
import AuthGuard from './components/Auth/AuthGuard';
import { BASE_URL } from './config/constant';
import Loader from './components/Loader/Loader';
import RecipientGuard from './components/Auth/RecipientGuard';
import ClientGuard from './components/Auth/ClientGuard';
// import PaymentSecurity from './components/popup/PaymentSecurity';
// import useGetPopupState from './hooks/useGetPopupState';

// const popups = {
//   [GET_ENUMS.popup.security]: PaymentSecurity,
// };

// const GetParameterPopups = () => {
//   const { mountedPopup, isOpened } = useGetPopupState();
//   const Component = popups[mountedPopup];

//   if (!Component) {
//     return null;
//   }

//   return <Component isOpened={isOpened} />;
// };

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
    {/* <GetParameterPopups /> */}
  </Suspense>
);

const routes = [
  {
    path: '/sign-in',
    component: lazy(() => import('./pages/auth/SignInPage')),
  },
  {
    path: '/p/:id',
    component: lazy(() => import('./pages/PaymentForm')),
  },

  {
    path: '/c/:id',
    component: lazy(() =>
      import('./pages/recipient/recipientCoWorker/RecipientCoWorker')
    ),
  },

  {
    path: '/sign-up',
    component: lazy(() => import('./pages/auth/SignUpPage')),
  },

  {
    exact: true,
    path: '/reset-password',
    component: lazy(() => import('./pages/auth/ResetPasswordPage')),
  },
  {
    exact: true,
    path: '/change-password',
    component: lazy(() => import('./pages/auth/ChangePasswordPage')),
  },
  {
    path: '/personal-data-processing',
    component: lazy(() => import('./pages/TermsPage')),
  },

  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/main_reports',
        // guard: ClientGuard,
        component: lazy(() => import('./pages/client/ReportsPage')),
      },
      {
        exact: true,
        path: '/superuser_reports',
        // guard: ClientGuard,
        component: lazy(() => import('./pages/client/ReportsPage')),
      },

      {
        exact: true,
        path: '/qr_recipients',
        guard: ClientGuard,

        component: lazy(() => import('./pages/client/PaymentsPage')),
      },
      {
        exact: true,
        path: '/add_recipient',
        guard: ClientGuard,
        component: lazy(() => import('./pages/client/AddRecipientPage.js')),
      },
      {
        exact: true,
        path: '/settings',
        guard: ClientGuard,
        component: lazy(() => import('./pages/client/SettingsPage')),
      },
      {
        exact: true,
        path: '/change-avatar/:id',

        component: lazy(() => import('./pages/AvatarPage')),
      },
      {
        exact: true,
        path: '/print-forms',

        component: lazy(() => import('./pages/PrintFormsPage')),
      },

      {
        exact: true,
        path: '/helpdesk',
        component: lazy(() => import('./pages/HelpDeckPage')),
      },
      {
        path: '/control',
        guard: AdminGuard,
        routes: [
          {
            exact: true,
            path: '/control/clients',
            component: lazy(() => import('./pages/admin/ControlPage')),
          },
          {
            exact: true,
            path: '/control/recipients',
            component: lazy(() => import('./pages/admin/ControlPage')),
          },
        ],
      },
      {
        exact: true,
        path: '/user/:id',
        guard: AdminGuard,
        component: lazy(() => import('./pages/admin/UserPage')),
      },
      {
        exact: true,
        path: '/recipier/:id',
        guard: AuthGuard,
        component: lazy(() => import('./pages/admin/RecipierPage')),
      },
      {
        exact: true,
        path: '/history',
        guard: RecipientGuard,
        component: lazy(() => import('./pages/recipient/HistoryPage')),
      },
      {
        // exact: true,
        path: '/home',
        guard: AuthGuard,
        // guard: RecipientGuard,
        component: lazy(() => import('./pages/recipient/HomePage')),
      },
      {
        exact: true,
        path: '/requisites',
        guard: AuthGuard,
        component: lazy(() => import('./pages/recipient/RequisitesPage')),
      },
      {
        exact: true,
        path: '/recipient_settings',
        guard: RecipientGuard,
        component: lazy(() =>
          import('./pages/recipient/SettingsRecipientPage')
        ),
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />,
      },
    ],
  },
];

export default routes;
