import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const isLogin = useSelector((state) => state.auth.user.id);

  if (!isLogin) {
    return <Redirect to="/sign-in" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
