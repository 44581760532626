import 'intro.js/introjs.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';

import { ConfigProvider } from './contexts/ConfigContext';
import App from './App';
import authReducer from './store/reducers/auth';
import Loader from './components/loaders/Loader';
import { fetchMe } from './store/actions/auth';
import paymentReducer from './store/reducers/payment';

import './index.scss';

const rootReducer = combineReducers({
  auth: authReducer,
  payment: paymentReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(ReduxThunk))
);

const jsx = (
  <Provider store={store}>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </Provider>
);

let hasRendered = false;

const renderApp = async () => {
  try {
    window.location.href.split('/')[3] !== 'p' &&
      (await store.dispatch(fetchMe()));

    if (!hasRendered) {
      ReactDOM.render(jsx, document.getElementById('root'));
      hasRendered = true;
    }
  } catch (e) {
    console.log(e.message);
  }
};

ReactDOM.render(<Loader />, document.getElementById('root'));

renderApp();

// reportWebVitals();
