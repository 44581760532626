import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const ClientGuard = ({ children }) => {
  const is_confirm = useSelector((state) => state.auth.user.is_confirm);
  const role = useSelector((state) => state.auth.user.role.id);

  if (role !== 'client' && role !== 'admin') {
    return <Redirect to="/history" />;
  } else if (!is_confirm) {
    return <Redirect to="/sign-in" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default ClientGuard;
