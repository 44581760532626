import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';

import routes, { renderRoutes } from './routes';
import { BASENAME } from './config/constant';
import { ConfigContext } from './contexts/ConfigContext';
import * as actionType from './store/actions';

const App = () => {
    const configContext = useContext(ConfigContext);
    const firstLogin = () => localStorage.getItem('HupuNotFirstLogin') || false;
    const isLogin = useSelector(state => state.auth?.user?.id);
    const isDisabled = useSelector(state => state.auth?.user?.is_disabled);
    const paymentPage =
        window.location.pathname.split('/')[1] === 'p' ||
        window.location.pathname.split('/')[1] === 'c';
    const [showSteps] = useState(firstLogin());

    const { dispatch } = configContext;

    useEffect(() => {
        if (!firstLogin()) {
            dispatch({ type: actionType.CHANGE_STEPS });
        }
    }, [dispatch, showSteps]);

    return (
        <React.Fragment>
            {isLogin && !paymentPage && isDisabled === 1 && (
                <p
                    style={{
                        marginBottom: '0',
                        background: 'red',
                        fontSize: '8px',
                        textAlign: 'center',
                        color: 'white',
                        zIndex: '999',
                    }}
                >
                    Пользователь заблокирован, Вы не можете принимать платежи, действия в личном
                    кабинете ограничены
                </p>
            )}
            <Router basename={BASENAME}>{renderRoutes(routes)}</Router>
        </React.Fragment>
    );
};

export default App;
