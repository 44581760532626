import hupu from '../../apis/hupu';

export const SIGN_UP = 'SIGN_UP';
export const LOGIN = 'LOGIN';
export const FETCH_ME = 'FETCH_ME';
export const LOGOUT = 'LOGOUT';
export const CHANGE_ME = 'CHANGE_ME';
export const CHANGE_USER = 'CHANGE_USER';
export const DELETE_USER = 'DELETE_USER';
export const CHANGE_RECIPIER = 'CHANGE_RECIPIER';
export const DELETE_RECIPIER = 'DELETE_RECIPIER';
export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_RECIPIENTS = 'FETCH_RECIPIENTS';
export const ADD_RECIPIER = 'ADD_RECIPIER';
export const RECALL = 'RECALL';
export const CHANGE_ME_RECIPIER = 'CHANGE_ME_RECIPIER';
export const CHANGE_BACKGROUND = 'CHANGE_BACKGROUND';

export const signUp = (phone, password, recaptcha) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      // formData.append('name', name);
      // formData.append('email', email);
      formData.append('phone', phone);
      formData.append('recaptcha', recaptcha);
      // formData.append('title', title);
      formData.append('password', password);
      // formData.append('password2', password2);

      const response = await hupu.put('/me', formData);

      if (response.data.success) {
        dispatch({ type: SIGN_UP, payload: response.data });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const login = (phone, password) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();

      formData.append('phone', phone);
      formData.append('password', password);
      // formData.append('role', role);

      const response = await hupu.post('/login', formData);

      if (response.data.success) {
        dispatch({ type: LOGIN, payload: response.data });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const fetchMe = (phone, password) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();

      formData.append('phone', phone);
      formData.append('password', password);

      const response = await hupu.get('/me', formData);

      if (response.data.success) {
        dispatch({ type: FETCH_ME, payload: response.data });
      }
    } catch (e) {
      // throw Error(JSON.parse(e.request.response).error);
      console.log(e.message);
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      const response = await hupu.post('/logout');

      if (response.data.success) {
        dispatch({ type: LOGOUT });
      }
    } catch (e) {
      // throw Error(JSON.parse(e.request.response).error);
      console.log(e.message);
    }
  };
};

export const changeBackground = (settings_background, settings_logo) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();

      settings_background !== null &&
        formData.append(
          'settings_background',
          settings_background.substring(1)
        );

      settings_logo.length > 0 &&
        formData.append('settings_logo', settings_logo);

      const response = await hupu.post('/me', formData);
      if (response.data.success) {
        dispatch({ type: CHANGE_BACKGROUND });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const changeMe = (
  name,
  title,
  position,
  email,
  address,
  city,
  hoard,
  password,
  password2,
  settings_guest_comission,
  settings_show_guest_comission,
  settings_show_title,
  is_recipient,
  avatar
) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();

      name !== null && formData.append('name', name);
      title !== null && formData.append('title', title);
      position !== null && formData.append('position', position);
      email !== null && formData.append('email', email);
      address !== null && formData.append('address', address);
      city !== null && formData.append('city', city);
      hoard !== null && formData.append('hoard', hoard);

      avatar && formData.append('avatar', avatar);
      password && formData.append('password', password);
      password2 && formData.append('password2', password2);
      `${settings_guest_comission}`.length > 0 &&
        formData.append(
          'settings_guest_comission',
          settings_guest_comission ? 1 : 0
        );
      `${settings_show_guest_comission}`.length > 0 &&
        formData.append(
          'settings_show_guest_comission',
          settings_show_guest_comission ? 1 : 0
        );
      `${settings_show_title}`.length > 0 &&
        formData.append('settings_show_title', settings_show_title ? 1 : 0);
      `${is_recipient}`.length > 0 &&
        formData.append('is_recipient', is_recipient ? 1 : 0);

      const response = await hupu.post('/me', formData);

      if (response.data.success) {
        dispatch({ type: CHANGE_ME });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const changeSettings = (
  settings_guest_comission,
  settings_show_guest_comission,
  settings_show_title,
  settings_show_feedback,
  settings_common_account
) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      `${settings_guest_comission}`.length > 0 &&
        formData.append(
          'settings_guest_comission',
          settings_guest_comission ? 1 : 0
        );
      `${settings_show_guest_comission}`.length > 0 &&
        formData.append(
          'settings_show_guest_comission',
          settings_show_guest_comission ? 1 : 0
        );
      `${settings_show_title}`.length > 0 &&
        formData.append('settings_show_title', settings_show_title ? 1 : 0);

      `${settings_show_feedback}`.length > 0 &&
        formData.append(
          'settings_show_feedback',
          settings_show_feedback ? 1 : 0
        );
      `${settings_common_account}`.length > 0 &&
        formData.append(
          'settings_common_account',
          settings_common_account ? 1 : 0
        );
      const response = await hupu.post('/me', formData);

      if (response.data.success) {
        dispatch({ type: CHANGE_ME });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const fetchClients = (limit, offset, order_by, dir, phone, status) => {
  return async (dispatch) => {
    try {
      const response = await hupu.get('/users', {
        params: {
          limit,
          offset,
          order_by,
          dir,
          phone: phone || null,
          ...status,
        },
      });

      if (response.data.success) {
        dispatch({ type: FETCH_CLIENTS, payload: response.data });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const fetchRecipients = (
  limit,
  offset,
  order_by,
  dir,
  date_from,
  date_to,
  phone,
  status
) => {
  return async (dispatch) => {
    try {
      const response = await hupu.get('/recipients', {
        params:
          date_from.length > 0
            ? {
                limit,
                offset,
                order_by,
                dir,
                date_from,
                date_to,
                phone: phone || null,
              }
            : {
                limit,
                offset,
                order_by,
                dir,
                phone: phone || null,
                ...status,
              },
      });

      if (response.data.success) {
        dispatch({ type: FETCH_RECIPIENTS, payload: response.data });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const changeUser = (
  id,
  name,
  title,
  position,
  email,
  address,
  city,
  password,
  password2,
  avatar
) => {
  return async (dispatch) => {
    const formData = new FormData();
    id !== null && formData.append('id', id);
    name !== null && formData.append('name', name);
    position !== null && formData.append('position', position);
    email !== null && formData.append('email', email);
    address !== null && formData.append('address', address);
    city !== null && formData.append('city', city);
    title !== null && formData.append('title', title);
    avatar && formData.append('avatar', avatar);
    if (password.length > 0) {
      formData.append('password', password);
      formData.append('password2', password2);
    }

    try {
      const response = await hupu.post(`/user/${id}`, formData);

      if (!!response.data.success) {
        await dispatch({ type: CHANGE_USER });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('id', id);

    try {
      const response = await hupu.delete(`/user/${id}`, formData);

      if (!!response.data.success) {
        await dispatch({ type: DELETE_USER });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const changeRecipier = (
  id,
  name,
  position,
  password,
  password2,
  avatar
) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('id', id);
    name !== null && formData.append('name', name);
    name !== null && formData.append('position', position);

    avatar && formData.append('avatar', avatar);
    if (password.length > 0) {
      formData.append('password', password);
      formData.append('password2', password2);
    }

    try {
      const response = await hupu.post(`/recipient/${id}`, formData);

      if (!!response.data.success) {
        await dispatch({ type: CHANGE_RECIPIER });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const deleteRecipier = (id) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append('id', id);

    try {
      const response = await hupu.delete(`/recipient/${id}`, formData);

      if (!!response.data.success) {
        await dispatch({ type: DELETE_RECIPIER });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const addRecipier = (name, phone) => {
  return async (dispatch) => {
    const formData = new FormData();

    name !== null && formData.append('name', name);
    phone !== null && formData.append('phone', phone);

    try {
      const response = await hupu.put('/recipient', formData);

      if (response.data.success) {
        dispatch({ type: ADD_RECIPIER, payload: response.data });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const recall = (id) => {
  return async (dispatch) => {
    try {
      const response = await hupu.post(`/recipient/${id}/invite`);

      if (response.data.success) {
        dispatch({ type: RECALL });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};

export const changeMeRecipier = (
  name,
  position,
  hoard,
  password,
  password2,
  avatar
) => {
  return async (dispatch) => {
    const formData = new FormData();

    name !== null && formData.append('name', name);
    position !== null && formData.append('position', position);
    hoard !== null && formData.append('hoard', hoard);

    avatar && formData.append('avatar', avatar);

    if (password.length > 0) {
      formData.append('password', password);
      formData.append('password2', password2);
    }

    try {
      const response = await hupu.post('/me', formData);
      if (response.data.success) {
        dispatch({ type: CHANGE_ME_RECIPIER, payload: response.data });
      }
    } catch (e) {
      throw Error(JSON.parse(e.request.response).error);
    }
  };
};
