import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import ReactNotification from 'react-notifications-component';

import avatar1 from '../../../../assets/images/user/user-12.jpg';
import { logout } from '../../../../store/actions/auth';
import hupu from '../../../../apis/hupu';
import error from '../../../../components/error/error';
import { ConfigContext } from '../../../../contexts/ConfigContext';

// import { Link } from 'react-router-dom';
const NavRight = () => {
    const user = useSelector(state => state.auth.user);
    const is_su = useSelector(state => state.auth.is_su);
    const dispatch = useDispatch();
    const history = useHistory();
    const configContext = useContext(ConfigContext);
    const { mobileToogler } = configContext.state;

    const logoutHandler = async () => {
        try {
            await dispatch(logout());
            history.push('/sign-in');
        } catch (e) {
            console.log(e.message);
        }
    };

    const becomeBack = async () => {
        try {
            const response = await hupu.post('/su_exit');
            if (!!response.data.success) {
                history.push('/main_reports');

                history.go(0);
            }
        } catch (e) {
            error('Не удалось стать другим пользователем, попробуйте позже...');
        }
    };

    const dropdownToggle = () => {
        return (
            <>
                <Dropdown.Toggle as={Link} variant='link' to='#' id='dropdown-basic'>
                    {user.avatar ? (
                        <div
                            style={{
                                background: `url(${user.avatar}) no-repeat center`,
                                backgroundSize: 'cover',
                                height: '40px',
                                width: '40px',
                                marginTop: '10px',
                                borderRadius: '10px',
                                marginBottom: '10px',
                            }}
                        />
                    ) : (
                        <img src={avatar1} className={` wid-40  img-radius `} alt='User Profile' />
                    )}
                    <div
                        className='toogle-info'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontWeight: 'bold',
                        }}
                    >
                        <span className='toogle-info__span'>
                            {user.name}{' '}
                            {(user.role.id === 'recipient' ||
                                (user.role.id === 'client' && user.is_recipient === 1)) &&
                                `  ${user.parent?.title ? `| ${user.parent?.title}` : ''}`}{' '}
                        </span>
                        {/* <span>{user.role.title}</span> */}
                        {(user.role.id === 'recipient' ||
                            (user.role.id === 'client' && user.is_recipient === 1)) && (
                            <span className='toogle-info__span'>{`Баланс: ${user.money} руб.`}</span>
                        )}
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight className='profile-notification'>
                    <div className='pro-head d-flex align-items-center toogle__head'>
                        {user.avatar ? (
                            <div
                                style={{
                                    background: `url(${user.avatar}) no-repeat center`,
                                    backgroundSize: 'cover',
                                    height: '40px',
                                    width: '40px',
                                    marginRight: '10px',
                                    borderRadius: '10px',
                                }}
                            />
                        ) : (
                            <img
                                src={avatar1}
                                className={` wid-40  img-radius`}
                                alt='User Profile'
                            />
                        )}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>{user.name || ''} </span>
                            <span> {` ${user.parent?.title || user.title || ''}`} </span>
                            <span>{user.position}</span>
                            {(user.role.id === 'recipient' ||
                                (user.role.id === 'client' && user.is_recipient === 1)) && (
                                <span>{`Баланс: ${user.money} руб.`}</span>
                            )}
                        </div>
                    </div>
                    <ListGroup as='ul' bsPrefix=' ' variant='flush' className={`pro-body`}>
                        <ListGroup.Item as='li' bsPrefix=' '>
                            <Link
                                to={
                                    user.role.id === 'recipient'
                                        ? '/recipient_settings'
                                        : '/settings'
                                }
                                className='dropdown-item'
                            >
                                <i className='feather icon-settings' /> Настройки
                            </Link>
                        </ListGroup.Item>

                        <ListGroup.Item as='li' bsPrefix=' '>
                            <Link to={`/change-avatar/${user.id}`} className='dropdown-item'>
                                <i className='feather icon-image' /> Поменять аватар
                            </Link>
                        </ListGroup.Item>

                        <ListGroup.Item as='li' bsPrefix=' '>
                            <Link
                                to='#'
                                className='dropdown-item'
                                onClick={is_su !== false ? becomeBack : logoutHandler}
                            >
                                {is_su !== false ? (
                                    <>
                                        <i
                                            style={{
                                                fontSize: '20px',
                                                color: 'black',
                                            }}
                                            className=' fa fa-user-secret'
                                        />
                                        Вернуться
                                    </>
                                ) : (
                                    <>
                                        <i className=' feather icon-log-out' />
                                        Выход
                                    </>
                                )}
                            </Link>
                        </ListGroup.Item>
                    </ListGroup>
                </Dropdown.Menu>
            </>
        );
    };

    return (
        <React.Fragment>
            <ReactNotification />

            <ListGroup as='ul' bsPrefix=' ' className='navbar-nav user-container ml-auto'>
                <ListGroup.Item className='pr-0 pl-0' as='li' bsPrefix=' '>
                    {mobileToogler ? (
                        <Dropdown className='drp-user d-flex justify-content-center' show>
                            {dropdownToggle()}
                        </Dropdown>
                    ) : (
                        <Dropdown className='drp-user d-flex justify-content-center'>
                            {dropdownToggle()}
                        </Dropdown>
                    )}
                </ListGroup.Item>
            </ListGroup>
        </React.Fragment>
    );
};

export default NavRight;
