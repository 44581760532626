class Payment {
  constructor(id, amount, ctime, stars, comment, icon) {
    this.id = id;
    this.amount = amount;
    this.ctime = ctime;
    this.stars = stars;
    this.comment = comment;
    this.icon = icon;
  }
}

export default Payment;

export const newPayment = (payment) => {
  return new Payment(
    payment.id,
    payment.amount,
    payment.ctime,
    payment.stars || 'нет оценки',
    payment.comment || '--',

    (
      <div>
        <i
          style={{
            fontSize: '25px',
            color: +payment.amount > 0 ? '#2ed8b6' : 'red',
          }}
          className={`fa fa-check-circle`}
        >
          <span className="sr-only" />
        </i>
      </div>
    )
  );
};
