export const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Навигация',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'main',
          title: 'Платежи',
          type: 'item',
          icon: 'fa fa-file',
          url: '/main_reports',
          classes: 'main',
          breadcrumbs: true,
        },
        // {
        //   id: 'print-forms',
        //   title: 'QR-коды',
        //   type: 'item',
        //   icon: 'fa fa-qrcode',
        //   url: '/print-forms',
        //   classes: 'print-forms',
        //   breadcrumbs: true,
        // },
        {
          id: 'reports',
          title: 'Сотрудники',
          type: 'item',
          icon: 'fa  fa-user-friends',
          url: '/qr_recipients',
          classes: 'reports',
          breadcrumbs: true,
        },

        {
          id: 'settings',
          title: 'Настройка',
          type: 'item',
          icon: 'fa fa-cog',
          url: '/settings',
          classes: 'settings',
          breadcrumbs: false,
        },
        {
          id: 'helpdesk',
          title: 'Поддержка',
          type: 'item',
          icon: 'fa fa-question-circle',
          url: '/helpdesk',
          classes: 'helpdesk',
          breadcrumbs: true,
        },
        {
          id: 'avatar',
          type: 'item',
          url: '/avatar-change',
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export const clientMenuItemsAsRecipient = {
  items: [
    {
      id: 'navigation',
      title: 'Навигация',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'main',
          title: 'Платежи',
          type: 'item',
          icon: 'fa fa-file',
          url: '/main_reports',
          classes: 'main',
          breadcrumbs: true,
        },
        // {
        //   id: 'print-forms',
        //   title: 'QR-коды',
        //   type: 'item',
        //   icon: 'fa fa-qrcode',
        //   url: '/print-forms',
        //   classes: 'print-forms',
        //   breadcrumbs: true,
        // },
        {
          id: 'reports',
          title: 'Сотрудники',
          type: 'item',
          icon: 'fa fa-user-friends',
          url: '/qr_recipients',
          classes: 'reports',
          breadcrumbs: true,
        },
        {
          id: 'requisites',
          title: 'Вывод средств',
          type: 'item',
          icon: 'fa fa-id-card',
          url: '/requisites',
          classes: 'requisites',
          breadcrumbs: true,
        },
        {
          id: 'settings',
          title: 'Настройка',
          type: 'item',
          icon: 'fa fa-cog',
          url: '/settings',
          classes: 'settings',
          breadcrumbs: false,
        },
        {
          id: 'helpdesk',
          title: 'Поддержка',
          type: 'item',
          icon: 'fa fa-question-circle',
          url: '/helpdesk',
          classes: 'helpdesk',
          breadcrumbs: true,
        },
        {
          id: 'avatar',
          type: 'item',
          url: '/avatar-change',
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export const adminMenuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Навигация',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'main',
          title: 'Пользователи',
          type: 'item',
          icon: 'fa fa-users',
          url: '/main_reports',
          breadcrumbs: false,
        },
        {
          id: 'reports',
          title: 'Платежи',
          type: 'item',
          icon: 'fa fa-file',
          url: '/superuser_reports',
          breadcrumbs: true,
        },
        {
          id: 'settings',
          title: 'Настройка',
          type: 'item',
          icon: 'fa fa-cog',
          url: '/settings',
          breadcrumbs: false,
        },
        {
          id: 'helpdesk',
          title: 'Поддержка',
          type: 'item',
          icon: 'fa fa-question-circle',
          url: '/helpdesk',
          breadcrumbs: true,
        },
        {
          id: 'avatar',
          type: 'item',
          url: '/avatar-change',
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export const recipientMenu = {
  items: [
    {
      id: 'navigation',
      title: 'Навигация',
      type: 'group',
      icon: 'icon-navigation',

      children: [
        {
          id: 'history',
          title: 'Платежи',
          type: 'item',
          icon: 'fa fa-history',
          url: '/history',
          classes: 'history',
          breadcrumbs: true,
        },
        {
          id: 'requisites',
          title: 'Вывод средств',
          type: 'item',
          icon: 'fa fa-id-card',
          url: '/requisites',
          classes: 'requisites',
          breadcrumbs: true,
        },

        {
          id: 'print-forms',
          title: 'QR-коды',
          type: 'item',
          icon: 'fa fa-qrcode',
          url: '/print-forms',
          classes: 'print-forms',
          breadcrumbs: true,
        },

        {
          id: 'settings',
          title: 'Настройка',
          type: 'item',
          icon: 'fa fa-cog',
          url: '/recipient_settings',
          classes: 'settings',
          breadcrumbs: false,
        },
        {
          id: 'helpdesk',
          title: 'Поддержка',
          type: 'item',
          icon: 'fa fa-question-circle',
          url: '/helpdesk',
          classes: 'helpdesk',
          breadcrumbs: true,
        },
        {
          id: 'avatar',
          type: 'item',
          url: '/avatar-change',
          breadcrumbs: false,
        },
      ],
    },
  ],
};
