import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const AdminGuard = ({ children }) => {
  const isAdmin = useSelector((state) => state.auth.user.role.is_admin);
  const is_confirm = useSelector((state) => state.auth.user.is_confirm);

  if (!isAdmin) {
    return <Redirect to="/main_reports" />;
  } else if (!is_confirm) {
    return <Redirect to="/sign-in" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AdminGuard;
