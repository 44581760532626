import { newPayment } from '../../models/Payment';
import {
  FETCH_PAYS,
  FETCH_REPORT,
  FETCH_REQUISITES,
  POST_SUPPORT,
} from '../actions/payment';

const initialState = {
  pays: [],
  counts: {
    paysCount: 0,
  },
  b2p_user: {
    active: '',
    address: '',
    auth_state: '',
    available_balance: '',
    birth_date: '',
    client_ref: '',
    currency: '',
    email: '',
    first_name: '',
    inn: '',
    last_name: '',
    patronymic: '',
    persondoc_issby: '',
    persondoc_issdate: '',
    persondoc_number: '',
    phone: '',
    reg_date: '',
    sector: '',
    signature: '',
    state: '',
  },
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYS:
      const pays = action.payload.list.map((payment) => {
        return newPayment(payment);
      });
      return {
        ...state,
        pays: [...pays],
        counts: {
          ...state.counts,
          paysCount: action.payload.count,
        },
      };

    case FETCH_REPORT:
      return state;
    case POST_SUPPORT:
      return state;

    case FETCH_REQUISITES:
      return { ...state, b2p_user: action.payload.b2p_user };
    default:
      return state;
  }
};

export default paymentReducer;
