import { Link } from 'react-router-dom';

class Recipier {
    constructor(
        id,
        name,
        position,
        img,
        title,
        email,
        phone,
        active,
        city,
        address,
        money,
        income,
        ctime,
        last_access,
        is_confirm
    ) {
        this.id = id;
        this.name = name;
        this.position = position;
        this.img = img;
        this.title = title;
        this.email = email;
        this.phone = phone;
        this.active = active;
        this.city = city;
        this.address = address;
        this.money = money;
        this.income = income;
        this.ctime = ctime;
        this.last_access = last_access;
        this.is_confirm = is_confirm;
    }
}

export default Recipier;

export const newRecipier = recipier => {
    return new Recipier(
        recipier.id,
        (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <span>{recipier.name}</span>{' '}
                <span
                    className={`badge badge-light-${
                        recipier.is_disabled ? 'danger' : recipier.is_confirm ? 'success' : 'danger'
                    }`}
                >
                    {recipier.is_disabled
                        ? 'Заблокирован'
                        : recipier.is_confirm
                        ? 'Активирован'
                        : 'Не активирован'}
                </span>
            </div>
        ),
        recipier.position,
        (
            <div>
                <img src={recipier.avatar} height={35} style={{ borderRadius: '5px' }} alt='' />
                <div className='overlay-edit'>
                    <Link
                        to={`/recipier/${recipier.id}`}
                        type='button'
                        className='btn btn-icon btn-success'
                    >
                        <i className='feather icon-edit'>
                            <span className='sr-only' />
                        </i>
                    </Link>
                </div>
            </div>
        ),
        recipier.parent?.title || recipier.title,
        recipier.email,
        recipier.phone,
        recipier.is_confirm,
        recipier.city || recipier.parent?.city,
        recipier.address || recipier.parent?.address,
        recipier.money,
        recipier.income,
        recipier.ctime,
        recipier.last_access,
        (
            <div>
                <span
                    className={`badge badge-light-${
                        recipier.is_disabled ? 'danger' : recipier.is_confirm ? 'success' : 'danger'
                        // !recipier.is_confirm ? 'danger' : 'success'
                    }`}
                >
                    {recipier.is_disabled
                        ? 'Заблокирован'
                        : recipier.is_confirm
                        ? 'Активирован'
                        : 'Не активирован'}
                </span>
                <div className='overlay-edit '>
                    <Link
                        to={`/recipier/${recipier.id}`}
                        type='button'
                        className='btn btn-icon btn-success'
                    >
                        <i className='feather icon-check-circle'>
                            <span className='sr-only' />
                        </i>
                    </Link>
                </div>
            </div>
        )
    );
};
