import { newClient } from '../../models/Client';
import { newRecipier } from '../../models/Recipier';
import {
  ADD_RECIPIER,
  CHANGE_ME,
  CHANGE_ME_RECIPIER,
  CHANGE_RECIPIER,
  CHANGE_USER,
  DELETE_RECIPIER,
  DELETE_USER,
  FETCH_CLIENTS,
  FETCH_ME,
  FETCH_RECIPIENTS,
  LOGIN,
  LOGOUT,
  RECALL,
  SIGN_UP,
  CHANGE_BACKGROUND,
} from '../actions/auth';

const initialState = {
  isLogin: false,
  is_su: false,
  user: {
    name: '',
    email: '',
    phone: '',
    title: '',
    address: '',
    city: '',
    role: {
      is_admin: 0,
    },
  },
  clients: [],
  recipients: [],
  counts: {
    recepientsCount: 0,
    clientsCount: 0,
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLogin: action.payload.is_confirm === 1 ? true : false,
        check_phone: action.payload.check_phone,
        user: {
          ...state.user,

          isConfirmed: action.payload.is_confirm,
        },
      };

    case SIGN_UP:
      return {
        ...state,
        isLogin: action.payload.is_confirm === 1 ? true : false,
        check_phone: action.payload.check_phone,
        user: {
          ...state.user,

          isConfirmed: action.payload.is_confirm,
        },
      };

    case FETCH_ME:
      return {
        ...state,
        isLogin: action.payload.user.is_confirm === 1 ? true : false,
        is_su: action.payload.is_su ? action.payload.is_su : false,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };

    case CHANGE_ME:
      return {
        ...state,
      };
    case CHANGE_BACKGROUND:
      return {
        ...state,
      };
    case LOGOUT:
      return initialState;
    case FETCH_CLIENTS:
      const clients = action.payload.list.map((client) => {
        return newClient(client);
      });
      return {
        ...state,
        clients: [...clients],
        counts: {
          ...state.counts,
          clientsCount: action.payload.count,
        },
      };

    case FETCH_RECIPIENTS:
      const recipients = action.payload.list.map((recipient) => {
        return newRecipier(recipient);
      });
      return {
        ...state,
        recipients: [...recipients],
        counts: {
          ...state.counts,
          recepientsCount: action.payload.count,
        },
      };
    case CHANGE_USER:
      return {
        ...state,
      };
    case DELETE_USER:
      return {
        ...state,
      };
    case CHANGE_RECIPIER:
      return { ...state };
    case DELETE_RECIPIER:
      return { ...state };

    case ADD_RECIPIER:
      return { ...state };
    case RECALL:
      return { ...state };

    case CHANGE_ME_RECIPIER:
      return { ...state };
    default:
      return state;
  }
};

export default authReducer;
