import React, { useState, useEffect, useContext } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

import {
  adminMenuItems,
  menuItems,
  recipientMenu,
  clientMenuItemsAsRecipient,
} from '../../../menu-items';
import { BASE_TITLE, BASENAME } from '../../../config/constant';
import { ConfigContext } from '../../../contexts/ConfigContext';

const Breadcrumb = (props) => {
  const [main, setMain] = useState([]);
  const [item, setItem] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  // const isAdmin = useSelector((state) => state.auth.user.role.is_admin);
  const role = useSelector((state) => state.auth.user.role.id);
  const is_recipient = useSelector((state) => state.auth.user.is_recipient);
  const configContext = useContext(ConfigContext);
  const { tooltipPage } = configContext.state;

  let navigation;
  if (role === 'recipient') {
    navigation = recipientMenu;
  } else if (role === 'admin') {
    navigation = adminMenuItems;
  } else {
    navigation = is_recipient === 1 ? clientMenuItemsAsRecipient : menuItems;
  }

  useEffect(() => {
    navigation.items.map((item, index) => {
      if (item.type && item.type === 'group') {
        getCollapse(item, index);
      }
      return false;
    });
  });

  const getCollapse = (item) => {
    if (item.children) {
      item.children.filter((collapse) => {
        if (collapse.type && collapse.type === 'collapse') {
          getCollapse(collapse);
        } else if (collapse.type && collapse.type === 'item') {
          if (document.location.pathname === BASENAME + collapse.url) {
            setMain(item);
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  let mainContent, itemContent;
  let breadcrumbContent = '';
  let title = '';

  // useEffect(() => {
  //   if (
  //     item.breadcrumbs === false &&
  //     window.location.href.split('/')[3] === 'recipier' &&
  //     window.location.href.split('/')[3] === 'settings'
  //   ) {
  //     setIsOpen(false);
  //   }
  // }, [item.breadcrumbs]);

  if (main && main.type === 'collapse') {
    mainContent = (
      <ListGroup.Item as='li' bsPrefix=' ' className='breadcrumb-item'>
        <Link to='#'>{main.title}</Link>
      </ListGroup.Item>
    );
  }

  useEffect(() => {
    // if (
    //   item &&
    //   (item.title === 'Главная' ||
    //     item.title === 'QR-коды' ||
    //     item.title === 'Вывод средств' ||
    //     item.title === 'Платежи')
    // ) {
    setIsOpen(tooltipPage);
    // }
  }, [tooltipPage, item]);

  if (item && item.type === 'item') {
    title = item.title;
    itemContent = (
      <ListGroup.Item as='li' bsPrefix=' ' className='breadcrumb-item'>
        <Link to='#'>{title}</Link>
      </ListGroup.Item>
    );

    const tooltip = () => {
      if (title === 'Главная') {
        return 'Функционал для управления сотрудниками предприятия, а также доступная отчетность внутри системы. Все указанные сотрудники будут иметь собственный доступ к личный кабинет и приложение.';
      } else if (title === 'QR-коды') {
        return 'Создайте QR-код или ссылку в необходимом вам формате. Мы предлагаем нашим клиентам реализовать печатную форму в виде визитный карточек или принтов. Также вам будет доступа интеграция с популярными CRM системами.';
      } else if (title === 'Вывод средств') {
        return (
          <p>
            В разделе вывод средств вы можете перевести денежные средства с
            виртуальной карты на любую банковскую карту, выпущенную на
            территории РФ.
            <br />
            Минимальная сумма вывода - 100 рублей. При выводе суммы до 3000 руб.
            комиссия 30 руб. При выводе суммы от 3000 руб. комиссия{' '}
            <b>отсутствует</b>
          </p>
        );
      } else if (title === 'Платежи') {
        return 'Вам доступна информация касательно полученного дохода, среднего чека и другие метрики. Заработанные средства вы можете потратить как с помощью виртуальный карты нашего сервиса, так и перевести на любую другую карту.';
      }

      return null;
    };

    if (
      item.breadcrumbs !== false &&
      window.location.href.split('/')[3] !== 'recipier' &&
      window.location.href.split('/')[3] !== 'settings' &&
      window.location.href.split('/')[3] !== 'user'
    ) {
      breadcrumbContent = (
        <div className='page-header'>
          <div className='page-block'>
            <div className='row align-items-center'>
              <div className='col-md-12'>
                <div className='page-header-title'>
                  <h5 className='m-b-10'>{title}</h5>
                </div>
                <ListGroup as='ul' bsPrefix=' ' className='breadcrumb'>
                  <ListGroup.Item
                    as='li'
                    bsPrefix=' '
                    className='breadcrumb-item'
                  >
                    <Link to='/'>
                      <i className='feather icon-home' />
                    </Link>
                  </ListGroup.Item>
                  {mainContent}
                  {itemContent}

                  {tooltip() !== null ? (
                    <>
                      {' '}
                      <i
                        className='fa fa-info-circle m-l-5'
                        style={{ color: '#4099ff' }}
                        id='popover'
                      ></i>
                      <UncontrolledPopover
                        trigger='hover'
                        placement='top'
                        target='popover'
                        isOpen={isOpen}
                        toggle={() => setIsOpen(!isOpen)}
                        // defaultOpen={true}
                      >
                        <PopoverHeader
                          style={{ background: '#4099ff', color: '#fff' }}
                        >
                          {title}
                        </PopoverHeader>
                        <PopoverBody>{tooltip()}</PopoverBody>
                      </UncontrolledPopover>{' '}
                    </>
                  ) : null}
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // isOpen === true &&
      //   dispatch({ type: actionType.TOOLTIPS_TOOGLE, tooltipPage: false });
    }
    document.title = title + BASE_TITLE;
  }

  return <React.Fragment>{breadcrumbContent}</React.Fragment>;
};

export default Breadcrumb;
