import { store } from 'react-notifications-component';

const error = (notificationMessage) => {
  store.addNotification({
    title: 'Ошибка',
    message: notificationMessage,
    type: 'danger',
    insert: 'center',
    container: 'top-center',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: { duration: 4000 },
    dismissable: { click: true },
  });
};

export default error;

export const success = (notificationMessage) => {
  store.addNotification({
    title: 'Успех',
    message: notificationMessage,
    type: 'success',
    insert: 'center',
    container: 'top-center',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: { duration: 4000 },
    dismissable: { click: true },
  });
};
